<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户行为分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="is_auth('usermsgtpl.smstemplate.savetpl')" icon="el-icon-plus" size="medium" type="primary"
              @click="addTemplate">添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="tpl_name" label="模板类型" show-overflow-tooltip />
        <el-table-column prop="name" label="模板名称" show-overflow-tooltip />
        <el-table-column prop="title" label="标题" show-overflow-tooltip />
        <el-table-column prop="content" label="内容" show-overflow-tooltip />
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button v-if="is_auth('usermsgtpl.smstemplate.savetpl')" @click="editTemplate(scope.row)" type="primary"
              size="mini">编辑
            </el-button>
            <el-button v-if="is_auth('usermsgtpl.smstemplate.deletetpl')" @click="deleteTemplate(scope.row)" type="danger"
              size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
    </div>
    <!--编辑-->
    <el-dialog v-loading="loading" :title="chooseTemplate.id ? '修改模板' : '新增模板'" :visible.sync="isAddShow" width="50%"
      :close-on-press-escape="false" :close-on-click-modal="false" @close="clearState">
      <el-row>
        <el-form size="medium" ref="form" :label-width="this.env.label_width">
          <el-col :span="24">
            <el-form-item label="模板选择">
              <el-select :disabled="chooseTemplate.id ? true : false" v-model="name" style="width: 50%"
                placeholder="请选择模板" @change="changeTemplate">
                <el-option v-for="item in templateData" :key="item.tpl_id" :label="item.tpl_name" :value="item.tpl_id" />
              </el-select>
            </el-form-item>
          </el-col>
          <div v-if="chooseTemplate.tpl_id">
            <!-- 模板名称 -->
            <el-col :span="24">
              <el-form-item label="模板名称">
                <el-input v-model="chooseTemplate.name" maxlength="20" show-word-limit placeholder="请填写模板名称" rows="3" />
              </el-form-item>
            </el-col>
            <!-- 模板名称 -->
            <el-col :span="24">
              <el-form-item label="标题">
                <el-input v-model="chooseTemplate.title" maxlength="120" show-word-limit placeholder="请填写模板标题" rows="3" />
              </el-form-item>
            </el-col>
            <!-- 模板内容 -->
            <el-col :span="24">
              <el-form-item label="内容">
                <el-input v-model="chooseTemplate.content" type="textarea" maxlength="120" show-word-limit
                  placeholder="请填写模板内容" rows="3" />
                <span v-if="chooseTemplate.is_short_links === 1" style="color:red">{short_links}代表插入短链的位置</span>
              </el-form-item>
            </el-col>
            <!-- 是否开启短链 -->
            <el-col :span="24">
              <el-form-item label="是否开启短链">
                <el-switch v-model="chooseTemplate.is_short_links" :active-value="1" :inactive-value="0" active-text="是"
                  inactive-text="否" />
              </el-form-item>
            </el-col>
            <!-- 如果开启了短链，则显示短链设置输入框 -->
            <el-col v-if="chooseTemplate.is_short_links === 1" :span="24">
              <el-form-item label="链接地址">
                <el-input v-model="chooseTemplate.links_url" show-word-limit placeholder="请填写需要跳转的小程序地址" rows="3" />
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </el-row>
      <el-row style="margin: 10px 0px">
        <el-button v-if="is_auth('usermsgtpl.smstemplate.savetpl')" size="medium" type="primary" @click="save">确 定
        </el-button>
        <el-button size="medium" @click="isAddShow = false">取 消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page_name: "短信模板列表",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      templateData: [], // 可用模板内容
      isAddShow: false,
      chooseTemplate: {
        is_short_links: 0,
        links_url: ''
      },
      name: "", // 选择的模板名称
    };
  },
  // 创建
  created() {
    this.init();
    // this.gettag();
  },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {};
      this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
      this.getTemplate();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "usermsgtpl.smstemplate.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 0) {
          this.tableData = res.data.list;
          this.count = res.data.total;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(res);
        }
      });
    },
    // 获取可用模板
    getTemplate() {
      let postdata = {
        api_name: "usermsgtpl.smstemplate.tplnames",
        token: this.Tool.get_l_cache("token"),
      };
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 0) {
          this.templateData = res.data.list;
        }
      });
    },
    // 保存与编辑
    save() {
      const { chooseTemplate } = this;
      const { tpl_id, name, title, content, id, links_url, is_short_links } = chooseTemplate;
      const postdata = {
        api_name: "usermsgtpl.smstemplate.savetpl",
        token: this.Tool.get_l_cache("token"),
        data: {
          tpl_id,
          name,
          title,
          content,
          links_url,
          is_short_links
        },
      };
      if (id) {
        postdata["data"]["id"] = id;
      }
      this.Tool.post_data("oss", postdata, (json) => {
        if (json.code === 0) {
          this.$message({
            message: id ? "修改成功" : "添加成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.is_search();
              this.isAddShow = false;
              this.chooseTemplate = {};
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 编辑模板
    editTemplate(item) {
      this.chooseTemplate = item;
      this.name = item.tpl_name;
      this.isAddShow = true;
    },
    // 选择可用模板
    changeTemplate(e) {
      this.chooseTemplate.tpl_id = e;
      // this.chooseTemplate = e;
    },
    //打开新增弹窗
    addTemplate() {
      const { templateData } = this;
      if (templateData.length > 0) {
        this.isAddShow = true;
      } else {
        this.$message.warning("暂无可用模板");
      }
    },
    // 删除模板
    deleteTemplate(item) {
      this.$confirm(`是否删除 ${item.name} 这条模板`, "确认删除", {})
        .then(() => {
          const postdata = {
            api_name: "usermsgtpl.smstemplate.deletetpl",
            token: this.Tool.get_l_cache("token"),
            data: {
              id: item.id,
            },
          };
          this.Tool.post_data("oss", postdata, (json) => {
            if (json.code === 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: this.env.message_duration,
                onClose: () => {
                  this.is_search();
                },
              });
            } else {
              this.Tool.errormes(json);
            }
          });
        })
        .catch(() => { });
    },
    // 初始化数据
    clearState() {
      this.isAddShow = false;
      this.chooseTemplate = {
        is_short_links: 0,
        links_url: ''
      };
      this.name = "";
    },
  },
};
</script>
